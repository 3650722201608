import React, { useState } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Link
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@emotion/react";
import { changeEmail, changePassword, deleteAccount } from "../actions/authActions";
import CustomAlert2 from "../components/customAlert2";
import { useDispatch } from "react-redux";

const ManageAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, logout, getAccessTokenSilently  } = useAuth0();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [accountChange, setAccountChange] = useState(false);
  const [accountChangeFailed, setAccountChangeFailed] = useState(false);
  const [accountDelete, setAccountDelete] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [failedPassword, setFailePassword] = useState(false);
  const fieldsetStyle = {
    width: "100%",
    margin: "auto",
    borderColor: theme.palette.text.third,
    borderRadius: 25,
    marginTop: "5vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1),
  };

  const handleBillingAccount = () => {
    // dispatch(createPortalSession({ user: user }));
  };

  const handleChangeEmail = async () => {
    const newEmail = prompt("Enter your new email:");
    dispatch(changeEmail({ userId: user.sub, email: newEmail }, emailChange, failedEmailChange));
  };

  const emailChange = () => {
    setAccountChange(true);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const failedEmailChange = () => {
    setAccountChangeFailed(true);
  }

  const handleChangePassword = async () => {
    const newPassword = prompt("Enter your new password:");
    dispatch(changePassword({ password: newPassword, userId: user.sub }, passwordChangeSuccess, passwordChangeFailed));
  };

  const handleChangePlan = () => {
    console.log("Change Plan");
  };

  const handleDeleteAccount = () => {
    setOpenDialog(true);
  };

  const confirmDeleteAccount = async () => {
    dispatch(deleteAccount({ userId: user.sub}, logOutSuccess));
  };

  const logOutSuccess = () => {
    handleCloseDialog();
    setAccountDelete(true);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }; 

  const passwordChangeSuccess = () => {
    setPasswordChange(true);
  };

  const passwordChangeFailed = () => {
    setFailePassword(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <section>
      <Box
        textAlign="center"
        sx={{
          width: "80vw",
          margin: "auto",
          "@media (min-width: 600px)": {
            width: "25%",
          },
        }}
      >
        <Typography variant="h6" textAlign="left" mx={2} mt={15} sx={{ color: "white" }}>
          Manage Account
        </Typography>
        <fieldset style={fieldsetStyle}>
          <List sx={{ width: "100%" }}>
            <ListItem button onClick={handleBillingAccount}>
              <ListItemText primary="Billing Account (Stripe)" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleChangeEmail}>
              <ListItemText primary="Change Email" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleChangePassword}>
              <ListItemText primary="Change Password" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleChangePlan}>
              <ListItemText primary="Change Plan" sx={{ textAlign: "center" }} />
            </ListItem>
            <ListItem button onClick={handleDeleteAccount} sx={{ marginTop: "20px!important" }}>
              <ListItemText primary="Delete Account" sx={{ textAlign: "center", color: "red" }} />
            </ListItem>
          </List>
        </fieldset>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Warning"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want delete your account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteAccount} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" justifyContent="center" mt={10}>
        <CustomAlert2
            openState={accountChange}
            severity="success"
            text="Email has been changed. Reloing with new email"
            autoHideDuration={3000}
            onClose={() => {
              setAccountChange(false);
            }}
          />

        <CustomAlert2
            openState={accountDelete}
            severity="success"
            text="Account has been deleted."
            autoHideDuration={3000}
            onClose={() => {
              setAccountDelete(false);
            }}
          />

        <CustomAlert2
            openState={passwordChange}
            severity="success"
            text="Password has been changed."
            autoHideDuration={3000}
            onClose={() => {
              setPasswordChange(false);
            }}
          />

        <CustomAlert2
            openState={failedPassword}
            severity="error"
            text="You can't change password. It could be password's strength is weak or you signup with Google account, If you want change your password, you must signup with Username-Password-Authentication method."
            autoHideDuration={5000}
            onClose={() => {
              setFailePassword(false);
            }}
          />

          <CustomAlert2
            openState={accountChangeFailed}
            severity="error"
            text="You can't change email. It could be email is not correct or you signup with Google account, If you want change your email, you must signup with Username-Password-Authentication method."
            autoHideDuration={5000}
            onClose={() => {
              setAccountChangeFailed(false);
            }}
          />
        </Box>
    </section>
  );
};

export default ManageAccount;
