import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AccountStatus = () => {
  const userType = useSelector((state) => state.stripe.userType);
  const availCalcCount = useSelector((state) => state.stock.availCalcCount);
  const subscriptionStatus = useSelector((state) => state.stripe.subscriptionStatus);
  const [text, setText] = useState("");
  const [color, setColor] = useState("black");
  const [bgColor, setBgColor] = useState("white");

  useEffect(() => {
    if (userType && userType.includes("Premium")) {
      setText("Premium | \u221E");
      setBgColor("text.third");
      setColor("white");
    } else if (userType && userType.includes("Elite")) {
      setText("Elite | \u221E");
      setBgColor("text.third");
      setColor("white");
    } else if (userType && userType == "Free" && subscriptionStatus !== "trial_expired") {
      setText(`Free Trial | \u221E`);
      setBgColor("white");
      setColor("black");
    } else if (userType && userType == "Free" && subscriptionStatus === "trial_expired") {
      setText(`Free | ${availCalcCount}/3`);
      setBgColor("white");
      setColor("black");
    }
  }, [userType, availCalcCount]);

  return (
    <Box bgcolor={bgColor} color={color} py={1} px={2} borderRadius={2.5} fontSize={20} fontWeight={600}>
      {text}
    </Box>
  );
};

export default AccountStatus;
